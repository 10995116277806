import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  googleMapsURL?: string
  languageCode: string
  longitude?: string
  latitude?: string
  phone?: string
  title?: string
  contactsList?: [any]
}

export const IntroContacts = memo(function IntroContacts({
  address,
  email,
  languageCode,
  phone,
  title,
  contactsList,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <IntroLeft>
          <IntroLeftText>
            <Deco />
            {title ? (
              <FadeInUp>
                <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
              </FadeInUp>
            ) : null}
            {address ? (
              <FadeInUp>
                <Text dangerouslySetInnerHTML={{ __html: address }}></Text>
              </FadeInUp>
            ) : null}
            <FadeInUp>
              <Info>
                <Subtitle>
                  {useVocabularyData('reservations', languageCode)}
                </Subtitle>
                <p>
                  <a
                    href={`tel:${phone}`}
                    onClick={() => {
                      if (isMobile) {
                        typeof window.gtag !== 'undefined' &&
                          window.gtag('event', 'Click', {
                            event_category: 'Website',
                            event_label: 'Phone Number',
                          })
                      }
                    }}
                  >
                    {phone}
                  </a>
                  <br />
                  <a
                    href={`mailto:${email}`}
                    onClick={() => {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Email Address',
                        })
                    }}
                  >
                    {email}
                  </a>
                </p>
              </Info>
            </FadeInUp>
          </IntroLeftText>
        </IntroLeft>
        <IntroRight>
          {contactsList && contactsList?.length > 1
            ? contactsList.map((contact, index) => (
                <React.Fragment key={index}>
                  <FadeInUp>
                    <Info>
                      {contact.role ? (
                        <Subtitle>{contact.role}</Subtitle>
                      ) : null}
                      <p>
                        {contact.name ? (
                          <>
                            {contact.name} <br />
                          </>
                        ) : null}
                        {contact.phone ? (
                          <a
                            href={`tel:${contact.phone}`}
                            onClick={() => {
                              if (isMobile) {
                                typeof window.gtag !== 'undefined' &&
                                  window.gtag('event', 'Click', {
                                    event_category: 'Website',
                                    event_label: 'Phone Number',
                                  })
                              }
                            }}
                          >
                            {contact.phone} <br />
                          </a>
                        ) : null}
                        {contact.email ? (
                          <>
                            <a
                              href={`mailto:${contact.email}`}
                              onClick={() => {
                                typeof window.gtag !== 'undefined' &&
                                  window.gtag('event', 'Click', {
                                    event_category: 'Website',
                                    event_label: 'Email Address',
                                  })
                              }}
                            >
                              {contact.email}
                            </a>{' '}
                            <br />
                          </>
                        ) : null}
                      </p>
                    </Info>
                  </FadeInUp>
                </React.Fragment>
              ))
            : null}
        </IntroRight>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 8vw 0 6vw 0;

  @media (max-width: 991px) {
    margin: 60px 0;

    p {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }
`

const Deco = styled.div`
  position: absolute;
  top: -6vw;
  left: -6vw;
  width: 13vw;
  height: 150%;
  min-height: 25vw;
  background-color: #ffffff;

  @media (max-width: 991px) {
    top: -40px;
    left: -30px;
    width: 120px;
    height: 120%;
  }
`

const IntroLeft = styled.div`
  width: 50%;
  position: relative;
  padding: 6vw 8vw 0vw 16vw;

  @media (max-width: 991px) {
    width: 100%;
    padding: 60px 60px 0 60px;
  }
`
const IntroLeftText = styled.div`
  position: relative;
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 3vw;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;
  position: relative;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Text = styled.p`
  margin: 2vw 0 4vw 0;
  position: relative;

  @media (max-width: 991px) {
    margin: 20px 0;
  }
`

const Info = styled.div`
  margin: 4vw 0;
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 991px) {
    margin: 30px 0;
  }
`

const Subtitle = styled.h3`
  font-size: 0.9vw;
  margin-bottom: 0.5vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  position: relative;

  @media (max-width: 1439px) {
    font-size: 14px;
  }
`

const IntroRight = styled.div`
  width: 50%;
  position: relative;
  padding: 6vw 10vw;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0px 60px;
  }
`
